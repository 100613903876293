
import { Vue, Component } from 'vue-property-decorator'
import { analyzeIDCard } from '@/utils/formatData'
import { WorkerDetail, FileInfo } from '@/types/worker'
import { ElForm } from 'element-ui/types/form'
import { idCardNo, phone, pInteger } from '@/utils/validate'
import UploadFile from '@/components/uploadFile/Index.vue'

@Component({
  components: { UploadFile }
})
export default class Add extends Vue {
  private isEdit = false
  private isAdding = false
  private workTypeList: [] = []
  private sexList = []
  private workerInfo: WorkerDetail = {
    projectId: '',
    projectName: '',
    workerName: '',
    workerMobile: '',
    workerSex: '',
    workerAge: '',
    workerCard: '',
    homeAddress: '',
    emergencyPerson: '',
    emergencyPersonMobile: '',
    workType: '',
    workerImgItem: {
      fileName: '',
      filePrefix: '',
      fileUrl: ''
    },
    workerImg: '',
    workerCardUpItem: {
      fileName: '',
      filePrefix: '',
      fileUrl: ''
    },
    workerCardUp: '',
    workerCardDoItem: {
      fileName: '',
      filePrefix: '',
      fileUrl: ''
    },
    workerCardDo: ''
  }

  private rules = {
    workerName: [
      { required: true, message: '请输入姓名', whitespace: true }
    ],
    projectId: [
      { required: true, message: '请选择项目', trigger: 'change' }
    ],
    identityCard: [
      { validator: idCardNo }
    ],
    mobile: [
      { validator: phone }
    ],
    age: [
      { validator: pInteger }
    ],
    emergencyPersonMobile: [
      { validator: phone }
    ]
  }

  get workerId () {
    return this.$route.params.workerId || ''
  }

  get normalProjectList () {
    return this.$store.state.normalProjectList
  }

  created () {
    this.getDicList()
    this.getSexList()
    if (this.workerId) {
      this.isEdit = true
      this.getWorkerInfo()
    }
  }

  getWorkerInfo () {
    this.$axios.get<WorkerDetail>(this.$apis.worker.selectYhWorkerByWorkerId,
      { workerId: this.workerId }).then(res => {
      this.workerInfo = res || []
      this.workerInfo.workerImgItem = {
        fileName: '',
        filePrefix: '',
        fileUrl: ''
      }
    })
  }

  // 工种
  getDicList () {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'workType' }).then(res => {
      this.workTypeList = res.workType || []
    })
  }

  // 性别
  getSexList () {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'workerSex' }).then(res => {
      this.sexList = res.workerSex || []
    })
  }

  workerCardChange (val: string) {
    const obj = analyzeIDCard(val)
    this.workerInfo.workerSex = obj.sex
    this.workerInfo.workerAge = obj.age
  }

  // 文件上传身份证成功返回
  onSuccess1 (file: FileInfo) {
    this.workerInfo.workerCardUpItem = file
    this.workerInfo.workerCardUp = file.filePrefix + file.fileUrl
  }

  // 文件上传工人照片成功返回
  onSuccess2 (file: FileInfo) {
    this.workerInfo.workerCardDoItem = file
    this.workerInfo.workerCardDo = file.filePrefix + file.fileUrl
  }

  // 文件上传身份证成功返回
  onSuccess3 (file: FileInfo) {
    this.workerInfo.workerImgItem = file
    this.workerInfo.workerImg = file.filePrefix + file.fileUrl
  }

  // 照片删除
  imgRemove (str: string) {
    const arr = ['', 'workerCardUpItem', 'workerCardDoItem', 'workerImgItem']
    const arr1 = ['', 'workerCardUp', 'workerCardDo', 'workerImg']
    const key = arr[Number(str)]
    const keys = arr1[Number(str)]
    this.workerInfo[key] = {
      fileName: '',
      filePrefix: '',
      fileUrl: ''
    }
    this.workerInfo[keys] = ''
  }

  submitForm () {
    (this.$refs.workerInfo as ElForm).validate(valid => {
      if (valid) {
        this.isAdding = true
        const url = this.isEdit ? this.$apis.worker.updateYhWorker : this.$apis.worker.insertYhWorker
        delete this.workerInfo.workerImgItem
        delete this.workerInfo.workerCardUpItem
        delete this.workerInfo.workerCardDoItem
        delete this.workerInfo.workerSexName
        delete this.workerInfo.workTypeName
        this.$axios.post(url, this.workerInfo).then(() => {
          this.$message.success(this.isEdit ? '修改成功' : '添加成功')
          this.goBack()
        }).finally(() => {
          this.isAdding = false
        })
      }
    })
  }

  goBack () {
    this.$router.push({ path: '/laborManageList/worker' })
  }
}
